import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/uE_1Oo7_gTk&">
    <SEO title="Daniel 6 - Living in Babylon" />
  </Layout>
)
export default SermonPost
